<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <FormulateForm
            ref="subcondominioForm"
            name="subcondominioForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <b-row>
              <!-- Nombre -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="nombre"
                  label="Nombre"
                  required
                  type="text"
                  validation="required"
                  validation-name="Nombre"
                />
              </b-col>

              <b-col cols="12" md="12">
                <!-- Condominio -->
                <FormulateInput
                  name="condominio_id"
                  label="Condominio"
                  :options="getCondominios()"
                  required
                  type="select"
                  validation="bail|required"
                  validation-name="Condominio"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.subcondominioForm ? $refs.subcondominioForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const formValues = () => ({
  nombre: '',
  condominio_id: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const STORE_MODULE = 'subcondominioModule'

export default {
  name: 'subcondominioForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    //
    // Subcondominio module
    ...mapState(STORE_MODULE, ['editingResource']),
    ...mapGetters(STORE_MODULE, ['isEditingResource']),
    ...mapGetters('condominioModule', ['getResource']),

    showFormTitle () {
      return this.isEditingResource
        ? `Editando subcondominio: '${this.editingResource.nombre}'`
        : 'Crear nuevo subcondominio'
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const {
            id,
            nombre,
            condominio
          } = this.editingResource
          this.formValues.id = id
          this.formValues.nombre = nombre
          this.formValues.condominio_id = condominio.id
          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingUser" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      console.log('submit')
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateResource(this.formValues)
        : await this.createResource(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/createResource`, form)
    },

    async updateResource (form) {
      return await this.$store.dispatch(`${STORE_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${STORE_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('subcondominioForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    },

    getCondominios () {
      const data = this.getResource.data
      const json = {}

      try {
        data.forEach(item => {
          json[item.id] = item.nombre
        })
      } catch (e) {}

      return json
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
